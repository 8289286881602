<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import { productService } from '@/services'
import Action from '@/components/Action'
import Icon from '@/components/Icon'
import TextareaField from '@/components/TextareaField'
import NumberField from '@/components/NumberField'
import OrderInput from '@/components/OrderInput'
import Datatable from '@/components/Datatable'
import DatatableHeader from '@/components/DatatableHeader'
import DatatableBody from '@/components/DatatableBody'
import DatatableFooter from '@/components/DatatableFooter'
import ProductImage from '@/components/ProductImage'
import ModalCheckoutOrder from '@/components/ModalCheckoutOrder'
import ModalDeleteOrder from '@/components/ModalDeleteOrder'
import ModalRemoveProduct from '@/components/ModalRemoveProduct'
import ModalConfirmation from '@/components/ModalConfirmation'
import logger from '@/logger/index'

export default {
  name: 'OrdersDetails',

  components: {
    Action,
    Icon,
    TextareaField,
    NumberField,
    OrderInput,
    Datatable,
    DatatableHeader,
    DatatableBody,
    DatatableFooter,
    ProductImage,
    ModalRemoveProduct,
    ModalCheckoutOrder,
    ModalDeleteOrder,
    ModalConfirmation,
  },

  data () {
    return {
      order: this.getSelectedOrder,
      selectedProduct: null,
      obs: null,
      showModalRemoveProduct: false,
      showModalCheckoutOrder: false,
      showModalDeleteOrder: false,
      showModalConfirm: false,
      loadingName: false,
      stockFavoriteCompany: null,
    }
  },

  computed: {
    ...mapState(['headerBack']),
    ...mapGetters('orders', ['getSelectedOrder']),
    ...mapGetters('userPermissions', ['getFavoriteCompany', 'getBlockSale']),

    orderTotal () {
      return this.order ? this.order.products.reduce((value, item) => {
        return value + (item.quantity * item.price)
      }, 0) : 0
    },

    totalStockQuantity () {
      return this.selectedProduct?.stock.reduce(
        (total, stock) => total + stock.quantity,
        0
      )
    },
  },

  watch: {
    orderTotal () {
      this.order.total = this?.orderTotal
      logger.debug('orderTotal: ', this.orderTotal)
    },
  },

  async created () {
    this.setHeaderBack({ name: 'orders' })
    const { id } = this.$route.params
    await this.getAllOrders({ id })
    this.selectOrder({ id })
    if (this.getSelectedOrder) {
      this.updateList()
    } else {
      this.$router.push({ name: 'orders' })
    }
  },

  destroyed () {
    this.setHeaderBack(null)
  },

  methods: {
    ...mapActions(['setHeaderBack', 'setFeedback']),
    ...mapActions('orders', ['updateOrderName', 'updateOrderObs', 'selectOrder', 'updateProductQuantity', 'getAllOrders']),
    ...mapActions('products', ['checkProductStock']),

    updateList () {
      this.order = {
        ...this.getSelectedOrder,
        products: this.getSelectedOrder.products.map(product => ({ ...product, changeQuantity: product.quantity })),
        total: this.orderTotal,
      }
      this.obs = this.order.obs
    },

    setDeleting () {
      if (this.getSelectedOrder) {
        this.updateList()
      }
    },

    handleSaveObs () {
      this.updateOrderObs({
        id: this.order.id,
        obs: this.obs,
      }).then(() => {
        this.setFeedback({ title: 'Observação do pedido alterado com sucesso', message: '', type: 'success' })
      })
    },

    handleDeleteOrder () {
      this.showModalDeleteOrder = true
    },

    handleCheckoutOrder () {
      this.showModalCheckoutOrder = true
    },

    openModalConfirmation () {
      this.showModalConfirm = true
    },

    handleSubmitName (value) {
      this.loadingName = true
      this.updateOrderName({
        id: this.order.id,
        name: value,
      }).then(() => {
        this.loadingName = false
        this.setFeedback({ title: 'Descrição do pedido alterado com sucesso', message: '', type: 'success' })
      })
    },

    handleCheckProductStock (product) {
      logger.debug('chamada do stock: ', product)
      this.selectedProduct = product
      return new Promise((resolve, reject) => {
        productService.checkStock({ id: product.id }).then(({ data }) => {
          this.selectedProduct.stock = data
          resolve(data)
        }).catch(({ response }) => {
          response !== undefined ? reject(response.data) : reject(response)
        })
      })
    },

    getStockFavoriteCompany () {
      if (this.selectedProduct && this.selectedProduct.stock) {
        this.stockFavoriteCompany = this.selectedProduct?.stock.find(stock => stock.company_id === this.getFavoriteCompany)
      }
    },

    updateQtdeProductToOrder (product) {
      this.updateProductQuantity({
        id: product.id,
        quantity: product.changeQuantity,
      }).then(() => {
        product.quantity = product.changeQuantity
        logger.debug('atualizei a Qtde para: ', product.quantity)
        this.setFeedback({ title: 'Produto atualizado com sucesso', message: '', type: 'success' })
        this.$refs.modalConfirmation.closeModal()
        this.$refs.modalConfirmation.loading = false
        this.updateList()
      })
    },

    async handleUpdateQuantity (product) {
      logger.debug('handleUpdateQuantity:', product)
      await this.handleCheckProductStock(product)
      await this.getStockFavoriteCompany()
      this.checkBlockSale(product)
    },

    checkBlockSale (product) {
      logger.debug('entrando no checkBlockSale: ', product)
      logger.debug(product.quantity, product.changeQuantity, this.stockFavoriteCompany.quantity, 'é maior: ', product.changeQuantity > this.stockFavoriteCompany.quantity)
      logger.debug('stockFavorite: ', this.stockFavoriteCompany)

      if (this.stockFavoriteCompany && product.changeQuantity > this.stockFavoriteCompany.quantity) {
        logger.debug('BLOQUEAR_VENDA: ', this.getBlockSale)
        this.handleBlockSale(product) // NOTE: Verifica o tipo de venda
      } else {
        logger.debug('A Qtde possui no estoque, irá inserir agora..')
        this.updateQtdeProductToOrder(product)
      }
    },

    handleBlockSale (product) {
      switch (this.getBlockSale) {
        case 0:
          logger.debug('case 0, vai atualizar a Qtde...')
          return this.updateQtdeProductToOrder(product)
        case 1:
          if (this.totalStockQuantity && product.changeQuantity > this.totalStockQuantity) {
            return this.setFeedback({ title: 'Não possui estoque disponível', message: 'Desculpe, mas não há mais unidades deste produto em estoque em todo Estado.', type: 'error' })
          }
          logger.debug('case 1, Abrindo o modal de confirmação...')
          return this.openModalConfirmation()
        case 2:
          logger.debug('case 2, Não possui estoque..')
          return this.setFeedback({ title: 'Não possui estoque disponível', message: 'Desculpe, mas não há mais unidades deste produto em estoque na filial preferencial.', type: 'error' })
        default:
          return []
      }
    },

    removeProduct (product) {
      this.selectedProduct = product
      this.showModalRemoveProduct = true
    },

    addProduct () {
      this.$router.push({ name: 'products' })
    },

  },
}
</script>

<template>
  <div :class="$classes['order-details']" v-if="order">
    <div :class="$classes['order-header']">
      <div :class="$classes['order-header-content']">
        <order-input :class="$classes['order-input']" :value="order.name" :loading="loadingName"
          @submit="handleSubmitName" @blur="handleSubmitName" />
        <div :class="$classes['order-header-actions']">
          <action v-if="$media.mobile" variant="flat" color="secondary" uppercase :class="$classes['order-back-btn']"
            :to="headerBack">
            <icon name="long-arrow-alt-left" btnIcon :class="$classes['order-back-btn-icon']" />
            Voltar
          </action>
          <action :disabled="order.status === 'Finalizado' || orderTotal === 0" color="tertiary" variant="flat" uppercase
            bold :class="$classes['order-finish-btn']" @click="handleCheckoutOrder">
            <icon id="link_finalizar" name="share" btnIcon />
            Finalizar pedido
          </action>
        </div>
        <div v-if="$media.mobile" :class="$classes['order-header-div']">
          <div v-if="$media.mobile" :class="$classes['order-header-total']">
            <span :class="$classes['order-header-total-label']">Total</span>
          </div>
          <div v-if="$media.mobile" :class="$classes['order-header-total-value']">
            <strong>{{ this.orderTotal | currency }}</strong>
          </div>
        </div>
      </div>
    </div>
    <div :class="$classes['order-content']">
      <datatable :items="order.products" v-if="!$media.mobile">
        <template slot="thead">
          <tr>
            <datatable-header width="70" text="Cód." align="center" />
            <datatable-header text="Produto" align="left" />
            <datatable-header width="140" text="Qtd." align="left" />
            <datatable-header width="90" text="Preço" align="left" />
            <datatable-header width="135" text="Subtotal" align="left" />
            <datatable-header width="140" />
          </tr>
        </template>
        <template slot="tbody" slot-scope="props">
          <tr :class="$classes['order-tbody']">
            <datatable-body bold :text="props.item.code" align="center" />
            <datatable-body :text="props.item.description" />
            <datatable-body>
              <div :class="$classes['number-field']">
                <div :class="[$classes['number-field-wrapper'], {
                  [$classes['has-changed']]: props.item.changeQuantity !== props.item.quantity,
                  [$classes['is-disabled']]: order.status === 'Finalizado' || props.item.changeQuantity === 0
                }]">
                  <number-field v-model="props.item.changeQuantity" :class="$classes['number-field-input']" :min="1"
                    :disabled="order.status === 'Finalizado'" />
                  <action v-if="props.item.changeQuantity !== props.item.quantity" :class="{
                    [$classes['number-field-action']]: true,
                    [$classes['is-disabled']]: props.item.changeQuantity <= 0
                  }" :disabled="props.item.changeQuantity <= 0" @click="handleUpdateQuantity(props.item)">
                    <icon name="check" :class="$classes['number-field-icon']" />
                  </action>
                </div>
              </div>
            </datatable-body>
            <datatable-body bold :text="props.item.price | currency" />
            <datatable-body bold :text="(props.item.quantity * props.item.price) | currency" />
            <datatable-body>
              <action :disabled="order.status === 'Finalizado'" color="secondary" variant="flat" uppercase
                @click="removeProduct(props.item)">
                <icon name="trash-alt" btnIcon />
                Excluir
              </action>
            </datatable-body>
          </tr>
        </template>
        <template slot="tfoot">
          <tr>
            <datatable-footer colspan="4">
              <action color="primary" variant="flat" uppercase bold :disabled="order.status === 'Finalizado'"
                @click="addProduct">
                <icon name="plus" btnIcon :class="$classes['btn-add-icon']" />
                Adicionar produtos
              </action>
            </datatable-footer>
            <datatable-footer bold :textTotal="'Total'" />
            <datatable-footer bold :textTotal="orderTotal | currency" />
          </tr>
          <tr>
            <datatable-footer colspan="4">
            </datatable-footer>
          </tr>
        </template>
      </datatable>
      <template v-if="$media.mobile">
        <template v-for="product in   order.products">
          <div :key="product.id" :class="$classes['product']">
            <div :class="$classes['product-columns']">
              <div :class="$classes['product-column']">
                <product-image :src="product.image" />
              </div>
              <div :class="$classes['product-column']">
                <span :class="$classes['product-column-label']">Cód</span>
                <span :class="$classes['product-column-value']">{{ product.code }}</span>
              </div>
              <div :class="$classes['product-column']">
                <span :class="$classes['product-column-label']">Produto</span>
                <span :class="$classes['product-column-value']">{{ product.description }}</span>
              </div>
            </div>
            <div :class="$classes['product-columns']">
              <div :class="$classes['product-column']">
                <span :class="$classes['product-column-label']">Qtd.</span>
                <div :class="$classes['number-field']">
                  <div :class="[$classes['number-field-wrapper'], {
                    [$classes['has-changed']]: product.changeQuantity !== product.quantity,
                    [$classes['is-disabled']]: order.status === 'Finalizado'
                  }]">
                    <number-field v-model="product.changeQuantity" :class="$classes['number-field-input']" :min="1"
                      :disabled="order.status === 'Finalizado'" />
                    <action v-if="product.changeQuantity !== product.quantity" :class="$classes['number-field-action']"
                      @click="handleUpdateQuantity(product)">
                      <icon name="check" :class="$classes['number-field-icon']" />
                    </action>
                  </div>
                </div>
              </div>
              <div :class="$classes['product-column']">
                <span :class="$classes['product-column-label']">Preço</span>
                <strong :class="$classes['product-column-value']">{{ product.price | currency }}</strong>
              </div>
              <div :class="$classes['product-column']">
                <span :class="$classes['product-column-label']">Subtotal</span>
                <strong :class="$classes['product-column-value']">{{ (product.quantity * product.price) | currency
                }}</strong>
              </div>
            </div>
            <div :class="$classes['product-columns']">
              <action :disabled="order.status === 'Finalizado'" color="secondary" variant="flat" uppercase
                @click="removeProduct(product)">
                <icon name="trash-alt" btnIcon />
                Excluir
              </action>
            </div>
          </div>
        </template>
        <div :class="$classes['order-content-action']">
          <action color="primary" variant="flat" uppercase bold :disabled="order.status === 'Finalizado'"
            @click="addProduct">
            <icon name="plus" btnIcon :class="$classes['btn-add-icon']" />
            Adicionar produtos
          </action>
        </div>
      </template>

      <div :class="$classes['order-obs']">
        <textarea-field label="Observações" placeholder="Escreva aqui" v-model="obs"
          :disabled="order.status === 'Finalizado'" />
      </div>
      <div :class="$classes['order-actions']">
        <action color="primary" variant="outlined" :disabled="order.status === 'Finalizado'" @click="handleDeleteOrder">
          <icon name="trash-alt" :class="$classes['btn-icon']" />
          Excluir pedido
        </action>
        <action v-if="obs !== order.obs" color="tertiary" variant="outlined" @click="handleSaveObs">
          <icon name="check" :class="$classes['btn-icon']" />
          Salvar alterações
        </action>
      </div>
    </div>

    <modal-remove-product @delete="setDeleting" :active.sync="showModalRemoveProduct" :order="order"
      :product="selectedProduct" />

    <modal-checkout-order :active.sync="showModalCheckoutOrder" :order="order" />

    <modal-delete-order :active.sync="showModalDeleteOrder" :order="order" />

    <modal-confirmation ref="modalConfirmation" :active.sync="showModalConfirm" :product="selectedProduct"
      :stock="stockFavoriteCompany && stockFavoriteCompany.quantity" @toOrder="updateQtdeProductToOrder" />
  </div>
</template>

<style module>
.order-details {
  background: #F3F3F4;
  min-height: calc(100vh - 85px);
}

.order-header {
  padding-bottom: 10px;
  margin-bottom: 20px;
  background: url('~@/assets/images/ellipse.png') repeat-x bottom left;
}

.order-header-content {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  height: 90px;
  background: #fff;
  padding: 15px 30px;
}

.order-content {
  padding: 30px;
}

tr.order-tbody>td {
  padding-top: 2em;
  padding-bottom: 2em;
}

.order-obs {
  margin-bottom: 30px;
}

.order-price {
  justify-content: flex-end;
}

.btn-icon {
  width: 20px;
  height: 20px;
  margin-right: 15px;
}

.btn-add-icon {
  height: 18px;
  width: 18px;
}

.order-actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.number-field {
  width: 122px;
}

.number-field-wrapper {
  display: inline-flex;
  align-items: center;
  border: 1px solid #A1A1A1;
  border-radius: 2px;
}

.number-field-wrapper.has-changed {
  border-color: #4A94D4;
}

.number-field-wrapper.is-disabled {
  border-color: rgba(162, 162, 162, 0.25);
}

.number-field-wrapper .number-field-input {
  background: #fff;
  border: 0;
  height: 28px;
  max-width: 90px;
  flex: 1;
}

.number-field-action {
  width: 30px;
  height: 28px;
  background: #4A94D4;
  color: #fff;
}

.number-field-action.is-disabled {
  background-color: #DBDBDB;
}

.number-field-icon {
  width: 16px;
  height: 16px;
}

@media (max-width: 767px) {
  .order-header {
    background: none;
    padding: 0;
  }

  .order-header-content {
    background: none;
    height: auto;
    flex-direction: column;
    align-items: flex-start;
    padding: 15px;
  }

  .order-header-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    order: 1;
    margin-bottom: 20px;
    width: 100%;
  }

  .order-input {
    order: 3;
  }

  .order-header-div {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    width: 100%;
    order: 2;
  }

  .order-header-line {
    border-top: 1px solid #000;
  }

  .order-header-horizontal {
    height: 1px;
    background-color: #000;
  }

  .order-header-total {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    width: 80%;
    order: 2;
    font-size: 14px;
  }

  .order-header-total-value {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    width: 20%;
    order: 2;
    font-size: 14px;
  }

  .order-total-label {
    font-size: 18px;
  }

  .order-header-total-label {
    text-transform: uppercase;
    margin-right: 20px;
  }

  .order-header-total-label-line {
    text-transform: uppercase;
    margin-right: 20px;
  }

  .order-content {
    padding: 15px;
  }

  .order-content-action {
    display: flex;
    justify-content: center;
    margin: 30px 0;
  }

  .product {
    background: #fff;
    border-radius: 2px;
    padding: 15px 10px;
  }

  .product+.product {
    margin-top: 2px;
  }

  .product-columns {
    display: flex;
    align-items: stretch;
  }

  .product-columns+.product-columns {
    margin-top: 20px;
  }

  .product-column+.product-column {
    margin-left: 15px;
  }

  .product-column-label {
    display: block;
    font-size: 14px;
    color: #a1a1a1;
    text-transform: uppercase;
    margin-bottom: 25px;
  }

  .product-column-value {
    display: block;
    font-size: 14px;
    color: #292929;
    max-width: 165px;
  }
}

@media (max-width: 380px) {
  .order-back-btn-icon {
    display: none;
  }
}
</style>
