<script>
import { mapActions } from 'vuex'
import Modal from '@/components/Modal'
import ModalHeader from '@/components/ModalHeader'
import Action from '@/components/Action'
import Icon from '@/components/Icon'

export default {
  name: 'ModalDeleteOrder',

  components: {
    Modal,
    ModalHeader,
    Action,
    Icon,
  },

  props: {
    active: {
      type: Boolean,
      default: false,
    },

    order: {
      type: Object,
      default: () => ({}),
    },
  },

  data () {
    return {
      loading: false,
    }
  },

  methods: {
    ...mapActions('orders', ['deleteOrder']),
    ...mapActions(['setFeedback']),

    closeModal () {
      this.$emit('update:active', false)
    },

    async handleDeleteOrder () {
      this.loading = true
      await this.deleteOrder({ id: this.order.id }).then(() => {
        this.loading = false
        this.setFeedback({ title: 'Pedido removido com sucesso!', message: '', type: 'success' })
        this.$router.replace({ name: 'orders' })
        this.closeModal()
      })
    },
  },
}
</script>

<template>
  <modal :active="active" @close="closeModal" :width="'500px'">
    <modal-header title="Excluir pedido" message="Você confirma a exclusão permanente do pedido?" />
    <div :class="$classes['order']">
      <span :class="$classes['order-label']">Pedido</span>
      <span :class="$classes['order-value']">{{ order.name }}</span>
    </div>
    <div :class="$classes['total']">
      <span :class="$classes['total-label']">Total</span>
      <span :class="$classes['total-value']">{{ order.total | currency }}</span>
    </div>
    <div :class="$classes['actions']">
      <action variant="flat" color="secondary" uppercase bold @click="closeModal">
        <icon v-if="!$media.mobile" name="long-arrow-alt-left" btnIcon />
        Cancelar
      </action>
      <action variant="contained" color="secondary" :loading="loading" :disabled="loading" @click="handleDeleteOrder">
        Excluir
      </action>
    </div>
  </modal>
</template>

<style module>
.order {
  margin-bottom: 30px;
}

.order-label {
  display: block;
  font-size: 12px;
  font-weight: bold;
  color: rgba(41, 41, 41, 0.8);
  margin-bottom: 10px;
}

.order-value {
  display: block;
  font-size: 14px;
  font-weight: 500;
  color: rgba(41, 41, 41, 0.8);
}

.total {
  display: flex;
  align-items: center;
}

.total-label {
  font-size: 14px;
  text-transform: uppercase;
  color: #a1a1a1;
  margin-right: 15px;
}

.total-value {
  font-size: 14px;
  font-weight: 500;
}

.actions {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}
</style>
