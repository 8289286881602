<script>
export default {
  name: 'DatatableBody',

  props: {
    text: {
      type: [String, Number],
      default: null,
    },

    align: {
      type: String,
      default: 'left',
    },

    bold: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    computedClasses () {
      return {
        [this.$classes['td']]: true,
        [this.$classes['is-bold']]: this.bold,
        [this.$classes['left']]: this.align === 'left',
        [this.$classes['right']]: this.align === 'right',
        [this.$classes['center']]: this.align === 'center',
      }
    },
  },
}
</script>

<template>
  <td :class="computedClasses">
    <span
      v-if="text"
      :class="[$classes['td-text'], {
        [this.$classes['is-bold']]: this.bold
      }]"
    >
      {{ text }}
      <slot name="text" />
    </span>
    <slot />
  </td>
</template>

<style module>
.td {
  background: #fff;
  height: 60px;
  padding: 4px;
}

.td-text {
  display: block;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.1em;
  color: #292929;
}

.is-bold {
  font-weight: 600;
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}

.center {
  text-align: center;
}
</style>
