<script>
import FormItem from '@/components/FormItem'
import FormLabel from '@/components/FormLabel'
import FormLabelMessage from '@/components/FormLabelMessage'

export default {
  name: 'TextareaField',

  components: {
    FormItem,
    FormLabel,
    FormLabelMessage,
  },

  props: {
    autocomplete: {
      type: String,
      default: null,
    },

    placeholder: {
      type: String,
      default: null,
    },

    label: {
      type: String,
      default: null,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    readonly: {
      type: Boolean,
      default: false,
    },

    value: {
      type: String,
      default: null,
    },

    validation: {
      type: Object,
      default: null,
    },

    autofocus: {
      type: Boolean,
      default: false,
    },
  },

  data () {
    return {
      isFocused: false,
    }
  },

  computed: {
    hasError () {
      return (this.validation && this.validation.$error) || false
    },

    computedClasses () {
      return {
        [[this.$classes['form-textarea']]]: true,
        [[this.$classes['is-disabled']]]: this.disabled,
      }
    },

    textareaName () {
      return `textarea-${this._uid}`
    },
  },

  watch: {
    value () {
      this.$nextTick(() => {
        this.calculateInputHeight()
      })
    },
  },

  mounted () {
    this.calculateInputHeight()
  },

  methods: {
    updateValue (value) {
      this.validation && this.validation.$touch()
      this.$emit('input', value)
    },

    updateFocus () {
      this.isFocused = true
    },

    updateBlur () {
      this.isFocused = false
      this.validation && this.validation.$touch()
      this.$emit('blur', this.value)
    },

    calculateInputHeight () {
      this.$refs.textarea.style.height = ''
      this.$refs.textarea.style.height = this.$refs.textarea.scrollHeight + 'px'
    },
  },
}
</script>

<template>
  <form-item>
    <div :class="[$classes['textarea-wrapper'], {
      [$classes['has-focus']]: isFocused,
      [$classes['is-disabled']]: disabled,
    }]">
      <form-label
        v-if="label"
        :text="label"
        :input-name="textareaName"
        :error="hasError"
        :disabled="disabled"
        :validation="validation"
      >
        <form-label-message
          :validation="validation"
        />
      </form-label>
      <textarea
        spellcheck="false"
        ref="textarea"
        :id="textareaName"
        :class="computedClasses"
        :autofocus="autofocus"
        :autocomplete="autocomplete"
        :placeholder="placeholder"
        :disabled="disabled"
        :readonly="readonly"
        :value="value"
        @input="updateValue($event.target.value)"
        @focus="updateFocus()"
        @blur="updateBlur()"
      />
    </div>
  </form-item>
</template>

<style module>
.textarea-wrapper {
  background: #E7EAEC;
  padding: 20px;
  border: 1px solid #E7EAEC;
  border-radius: 2px;
}

.textarea-wrapper.has-focus {
  border-color: #4A94D4;
}

.textarea-wrapper.is-disabled {
  border-color: rgba(231, 234, 236, 0.2);
  background-color: rgba(231, 234, 236, 0.2);
}

.form-textarea {
  margin-top: 10px;
  border: 0;
  display: block;
  width: 100%;
  height: 35px;
  flex: 1;
  font-size: 16px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Noto Sans", Ubuntu, Cantarell, "Helvetica Neue", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  color: #292929;
  background: none;
  resize: none;
}

.form-textarea.is-disabled {
  cursor: text;
}
</style>
