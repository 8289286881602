<script>
export default {
  name: 'DatatableFooter',

  props: {
    text: {
      type: String,
      default: null,
    },
    textTotal: {
      type: String,
      default: null,
    },
    align: {
      type: String,
      default: 'left',
    },

    bold: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    computedClasses () {
      return {
        [this.$classes['td']]: true,
        [this.$classes['is-bold']]: this.bold,
        [this.$classes['left']]: this.align === 'left',
        [this.$classes['right']]: this.align === 'right',
        [this.$classes['center']]: this.align === 'center',
      }
    },
  },
}
</script>

<template>
  <td :class="computedClasses">
    <span v-if="text" :class="$classes['td-text']">{{ text }}</span>
    <span v-if="textTotal" :class="$classes['td-textTotal']">{{ textTotal }}</span>
    <slot />
  </td>
</template>

<style module>
.td {
  vertical-align: middle;
  height: 60px;
}

.td-text {
  display: block;
  font-size: 14px;
  text-transform: uppercase;
  color: #292929;
  line-height: 18px;
  letter-spacing: 0.1em;
}

.td-textTotal {
  display: block;
  font-size: 18px;
  text-transform: uppercase;
  color: #292929;
  line-height: 18px;
  letter-spacing: 0.06em;
}

.is-bold {
  font-weight: bold;
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}

.center {
  text-align: center;
}
</style>
