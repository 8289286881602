<script>
export default {
  name: 'Datatable',

  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
}
</script>

<template>
  <table :class="$classes['datatable']">
    <thead v-if="$slots.thead">
      <slot name="thead" />
    </thead>
    <tbody>
      <slot
        v-for="(item, index) in items"
        name="tbody"
        :item="item"
        :index="index"
      />
    </tbody>
    <tfoot v-if="$slots.tfoot">
      <slot name="tfoot" />
    </tfoot>
  </table>
</template>

<style module>
.datatable {
  width: 100%;
  border-spacing: 0;
  border-collapse: separate;
  margin-bottom: 10px;
}
</style>
