<script>
export default {
  name: 'DatatableHeader',

  props: {
    text: {
      type: String,
      default: null,
    },

    align: {
      type: String,
      default: 'left',
    },
  },

  computed: {
    computedClasses () {
      return {
        [this.$classes['th']]: true,
        [this.$classes['left']]: this.align === 'left',
        [this.$classes['right']]: this.align === 'right',
        [this.$classes['center']]: this.align === 'center',
      }
    },
  },
}
</script>

<template>
  <th
    :class="computedClasses"
  >
    <span v-if="text" :class="$classes['th-text']">{{ text }}</span>
    <slot />
  </th>
</template>

<style module>
.th {
  vertical-align: top;
  height: 40px;
}

.th-text {
  display: block;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  color: #a1a1a1;
  line-height: 18px;
  letter-spacing: 0.1em;
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}

.center {
  text-align: center;
}
</style>
