<script>
import Action from '@/components/Action'
import Icon from '@/components/Icon'

export default {
  name: 'OrderInput',

  components: {
    Action,
    Icon,
  },

  props: {
    value: {
      type: String,
      default: null,
    },

    loading: {
      type: Boolean,
      default: false,
    },
  },

  data () {
    return {
      isEditable: false,
      mutableValue: null,
    }
  },

  computed: {
    inputName () {
      return `input-${this._uid}`
    },

    iconName () {
      return this.isEditable ? 'check' : 'pen'
    },
  },

  mounted () {
    this.mutableValue = this.value
  },

  methods: {
    handleSubmit () {
      this.isEditable = !this.isEditable
      if (this.isEditable) {
        this.$nextTick(() => {
          this.$refs.input.focus()
        })
      } else {
        this.$emit('submit', this.mutableValue)
      }
    },
  },
}
</script>

<template>
  <form @submit.prevent="handleSubmit" :class="$classes['order-input']">
    <label
      :for="inputName"
      :class="$classes['label']"
    >
      Pedido
    </label>
    <div :class="$classes['input-wrapper']">
      <input
        type="text"
        ref="input"
        :id="inputName"
        :disabled="!isEditable"
        :class="$classes['input']"
        v-model="mutableValue"
      />
      <action
        type="submit"
        :class="$classes['btn']"
        :loading="loading"
        :disabled="loading"
      >
        <icon v-if="!loading" :name="iconName" :class="$classes['btn-icon']" />
      </action>
    </div>
  </form>
</template>

<style module>
.order-input {
  flex: 1;
  max-width: 440px;
  margin-right: 20px;
}

.label {
  display: inline-block;
  font-size: 12px;
  font-weight: bold;
  line-height: 20px;
  margin-bottom: 5px;
}

.input-wrapper {
  display: flex;
}

.input {
  display: block;
  width: 100%;
  flex: 1;
  height: 40px;
  padding: 0 20px;
  border: 0;
  background: #F3F3F4;
  border-radius: 0;
  font-size: 16px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Noto Sans", Ubuntu, Cantarell, "Helvetica Neue", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  color: #292929;
}

.btn {
  background: #4A94D4;
  color: #fff;
  width: 50px;
  height: 40px;
}

.btn-icon {
  width: 18px;
  height: 18px;
}

@media (max-width: 767px) {
  .order-input {
    max-width: none;
    width: 100%;
    margin-right: 0;
  }

  .input {
    background: #fff;
  }
}
</style>
