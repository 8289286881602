<script>
import Icon from '@/components/Icon'

export default {
  name: 'ProductImage',

  components: {
    Icon,
  },

  props: {
    src: {
      type: String,
      default: null,
    },
  },
}
</script>

<template>
  <div :class="$classes['product-image-wrapper']">
    <icon
      v-if="!src"
      name="image"
      :class="$classes['product-image-icon']"
    />
    <img
      v-else
      :src="src"
      :class="$classes['product-image']"
    />
  </div>
</template>

<style module>
.product-image-wrapper {
  background: #ccc;
  color: #fff;
  border-radius: 5px;
  width: 48px;
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.product-image-icon {
  width: 18px;
  height: 18px;
}

.product-image {
  display: block;
  width: 100%;
  height: 100%;
}
</style>
