<script>
import { mapActions } from 'vuex'
import Modal from '@/components/Modal'
import ModalHeader from '@/components/ModalHeader'
import Action from '@/components/Action'
import Icon from '@/components/Icon'

export default {
  name: 'ModalRemoveProduct',

  components: {
    Modal,
    ModalHeader,
    Action,
    Icon,
  },

  props: {
    active: {
      type: Boolean,
      default: false,
    },

    order: {
      type: Object,
      default: null,
    },

    product: {
      type: Object,
      default: null,
    },
  },

  data () {
    return {
      loading: false,
    }
  },

  methods: {
    ...mapActions('orders', ['removeProduct']),

    closeModal () {
      this.$emit('update:active', false)
    },

    sendDeleting () {
      this.$emit('delete')
    },

    handleRemoveProduct () {
      this.loading = true
      this.removeProduct({
        id: this.order.id,
        product_id: this.product.id,
      }).then(() => {
        this.loading = false
        this.closeModal()
        this.sendDeleting()
      })
    },

  },
}
</script>

<template>
  <modal
    :active="active"
    @close="closeModal"
    :width="'500px'"
  >
    <modal-header
      title="Excluir produto do pedido"
    />
    <template v-if="order && product">
      <div :class="$classes['order']">
        <span :class="$classes['order-label']">Pedido</span>
        <span :class="$classes['order-value']">{{ order.name }}</span>
      </div>
      <div :class="$classes['product']">
        <span :class="$classes['product-label']">Produto</span>
        <span :class="$classes['product-value']">{{ product.description }}</span>
      </div>
      <div :class="$classes['product-quantity-price']">
        <div :class="$classes['product-quantity']">
          <span :class="$classes['product-quantity-label']">Qtd.:</span>
          <span :class="$classes['product-quantity-value']">{{ product.quantity }}</span>
        </div>
        <div :class="$classes['product-price']">
          <span :class="$classes['product-price-label']">Subtotal:</span>
          <span :class="$classes['product-price-value']">{{ product.quantity * product.price | currency }}</span>
        </div>
      </div>
      <div :class="$classes['actions']">
        <action
          variant="flat"
          color="secondary"
          type="button"
          uppercase
          bold
          @click="closeModal"
        >
          <icon v-if="!$media.mobile" name="long-arrow-alt-left" btnIcon />
          Cancelar
        </action>
        <action
          variant="contained"
          color="secondary"
          type="submit"
          :loading="loading"
          :disabled="loading"
          @click="handleRemoveProduct"
        >
          Excluir
        </action>
      </div>
    </template>
  </modal>
</template>

<style module>
.order {
  margin-bottom: 35px;
}

.order-label {
  display: block;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 1.2px;
  color: rgba(41,41,41,0.8);
  line-height: 20px;
}

.order-value {
  display: block;
  font-size: 14px;
  letter-spacing: 1.4px;
  color: rgba(41,41,41,0.8);
}

.product {
  margin-bottom: 30px;
}

.product-label {
  display: block;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 1.2px;
  color: rgba(41,41,41,0.8);
  line-height: 20px;
  margin-bottom: 10px;
}

.product-value {
  display: block;
  font-size: 14px;
  letter-spacing: 1.4px;
  color: rgba(41,41,41,0.8);
}

.product-quantity-price {
  display: flex;
  align-items: center;
}

.product-quantity {
  display: flex;
  align-items: center;
  width: 120px;
}

.product-quantity-label {
  font-size: 12px;
  text-transform: uppercase;
  color: #a1a1a1;
  margin-right: 15px;
}

.product-quantity-value {
  font-size: 12px;
  font-weight: 500;
}

.product-price {
  display: flex;
  align-items: center;
}

.product-price-label {
  font-size: 12px;
  text-transform: uppercase;
  color: #a1a1a1;
  margin-right: 5px;
}

.product-price-value {
  font-size: 12px;
  font-weight: bold;
}

.actions {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}
</style>
